



























































































































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import Talk from "talkjs";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import MessageCenterModal from "@/components/shared/MessageCenterModal.vue";

import attendeeSelectionVuexModule from "@/store/vuex-modules/attendeeSelection";
const attendeeSelectionStore = getModule(attendeeSelectionVuexModule);

import {
    TalkConversationParticipant,
    AttendeeDataObject
} from "@/types/interfaces";

interface ThisPageOptions {
    textHeader?: string;
    maxAttendeesToMessage?: number;
}

export default Vue.extend({
    components: {
        PageTitleHeader,
        MessageCenterModal
    },
    data() {
        return {
            talkInbox: null as Talk.Inbox | null,
            isConversationModalActive: false,
            createErrorMessage: "",
            conversationModalType: "new",
            selectedConversation: null as Talk.ConversationData | null,
            conversationName: "",
            inboxTimeout: 0,
            initialAttendees: [] as Array<AttendeeDataObject>
        };
    },
    computed: {
        pageOptions(): ThisPageOptions {
            return this.$store.getters.getPageOptions("messageCenter");
        },

        textHeader(): string {
            return this.pageOptions.textHeader
                ? this.pageOptions.textHeader
                : "MESSAGE CENTER";
        },

        maxConversationPartners(): number {
            return this.pageOptions.maxAttendeesToMessage
                ? this.pageOptions.maxAttendeesToMessage
                : 4;
        },

        maxSelectedConversationPartners(): number {
            return this.conversationModalType == "new"
                ? this.maxConversationPartners - 1
                : this.maxConversationPartners;
        },

        talkModule(): any {
            return this.$store.state.talkVuexModule;
        },

        talkSession(): Talk.Session {
            return this.talkModule.talkSession;
        },

        talkUser(): Talk.User {
            return this.talkModule.talkUser;
        },

        userInfo(): Record<string, any> {
            return this.$store.getters.userInfo;
        },

        userId(): string {
            return this.userInfo.id;
        },

        talkMeetingPrefix(): string {
            return this.$store.getters.talkPrefix;
        },

        calloutText(): string {
            return this.$store.getters.getPageOptions("messageCenter").content
                ?.calloutText
                ? this.$store.getters.getPageOptions("messageCenter").content
                      .calloutText
                : "Want to start a conversation?";
        },

        linkText(): string {
            return this.$store.getters.getPageOptions("messageCenter").content
                ?.linkText
                ? this.$store.getters.getPageOptions("messageCenter").content
                      .linkText
                : "Find someone in the Attendee Search";
        },

        isConversationInitiator(): boolean {
            return this.talkUser.id === this.selectedConversationInitiator;
        },

        isSelectedConversationAppointment(): boolean {
            return this.selectedConversation?.custom?.isAppointment == "true";
        },

        canAddToConversation(): boolean {
            return (
                !this.isSelectedConversationAppointment &&
                this.isConversationInitiator &&
                this.selectedConversationParticipants.length <
                    this.maxConversationPartners
            );
        },

        conversationCustom(): Talk.CustomData {
            return this.selectedConversation?.custom
                ? this.selectedConversation?.custom
                : {};
        },

        hasSelectedConversation(): boolean {
            return this.selectedConversation != null;
        },

        cantAddErrorMessage(): string {
            if (this.isSelectedConversationAppointment) {
                return "You cannot add users to appointment conversations.";
            }

            if (!this.isConversationInitiator) {
                return "Only the conversation creator can add more users to a conversation.";
            }

            if (
                this.selectedConversationParticipants.length >=
                this.maxConversationPartners
            ) {
                return "You cannot add any more users to this conversation.";
            }

            return "";
        },

        /**
         * Talk conversations don't store participants by default, so we're storing their parts in strings in the custom attributes.
         */
        selectedConversationParticipants(): Array<TalkConversationParticipant> {
            const users = this.conversationCustom?.users
                ? this.conversationCustom.users.split("|")
                : [];
            const names = this.conversationCustom?.names
                ? this.conversationCustom.names.split("|")
                : [];
            const companyNames = this.conversationCustom?.companyNames
                ? this.conversationCustom.companyNames.split("|")
                : [];

            if (
                users.length === names.length &&
                names.length === companyNames.length
            ) {
                const participants = [];

                for (let i = 0; i < users.length; i++) {
                    participants.push({
                        attendeeId: users[i],
                        name: names[i],
                        companyName: companyNames[i]
                    });
                }

                return participants;
            } else {
                return [];
            }
        },

        selectedConversationInitiator(): string {
            return this.conversationCustom?.initiator
                ? this.conversationCustom.initiator
                : "";
        },

        talkContainerClasses(): string {
            return this.hasSelectedConversation ? "xl:w-2/3" : "w-full";
        }
    },
    watch: {
        userId() {
            this.setupInbox();
        },
        talkSession() {
            this.setupInbox();
        },
        talkUser() {
            this.setupInbox();
        }
    },

    mounted() {
        this.setupInbox();
    },

    methods: {
        /**
         * Initialize the TalkJS inbox, and if we have any params from the route, open the create new conversation modal.
         */
        setupInbox() {
            clearInterval(this.inboxTimeout);

            if (this.talkUser && this.talkMeetingPrefix) {
                Talk.ready.then(() => {
                    const conversation = null;

                    // We've been passed data from somewhere else to start a new conversation, so do so.
                    if (this.$route.params.attendeeId) {
                        const attendeeObj = {
                            attendeeId: this.$route.params.attendeeId,
                            firstName: this.$route.params.firstName
                                ? this.$route.params.firstName
                                : "",
                            lastName: this.$route.params.lastName
                                ? this.$route.params.lastName
                                : "",
                            companyName: this.$route.params.companyName
                                ? this.$route.params.companyName
                                : "",
                            email: this.$route.params.email
                                ? this.$route.params.email
                                : null
                        };

                        this.initialAttendees.push(attendeeObj);
                        this.isConversationModalActive = true;
                    }

                    const talkContainer = document.getElementById(
                        "talkjs-container"
                    );
                    this.talkInbox = conversation
                        ? this.talkModule.talkSession.createInbox({
                              selected: conversation,
                              showFeedHeader: false
                          })
                        : this.talkModule.talkSession.createInbox({
                              showFeedHeader: false
                          });
                    if (this.talkInbox != null) {
                        this.talkInbox.mount(talkContainer);

                        this.talkInbox.on("conversationSelected", (e) => {
                            if (e.conversation) {
                                this.setSelectedConversation(e.conversation);
                            }
                        });

                        this.talkInbox.on("sendMessage", (e) => {
                            const logData = {
                                type: "SentMessage",
                                conversationId: e.conversation.id
                                    ? e.conversation.id
                                    : ""
                            };

                            this.$store
                                .dispatch("appendLogEntry", logData)
                                .catch((err) =>
                                    console.log(
                                        `Error logging user log in event: ${logData}, ${err}, ${err.stack}`
                                    )
                                );
                        });
                    }
                });
            } else if (!this.talkMeetingPrefix) {
                console.log("Talk not initialized, no meeting prefix set");
            } else {
                console.log("Talk not initialized, not loading inbox");
            }

            if (!this.talkInbox) {
                this.inboxTimeout = window.setTimeout(() => {
                    console.log("trying again to setup inbox in 2 seconds");
                    this.setupInbox();
                }, 2000);
            }
        },

        openCreateModal() {
            this.conversationModalType = "new";
            this.conversationName = "";
            this.isConversationModalActive = true;
        },

        openModifyModal() {
            this.conversationModalType = "modify";
            this.conversationName = this.selectedConversation?.subject
                ? this.selectedConversation.subject
                : "";
            this.isConversationModalActive = true;
        },

        /**
         * Set the currently selected conversation, either when initializing inbox or after modifying it to refresh the display.
         */
        setSelectedConversation(conversation: Talk.ConversationData) {
            this.selectedConversation = conversation;
        },

        handleFinishedConversation(conversation: Talk.ConversationData) {
            this.selectedConversation = conversation;
            this.resetModal();
        },

        resetModal() {
            this.isConversationModalActive = false;
            this.conversationModalType = "new";
            attendeeSelectionStore.resetAttendees();
        },

        showErrorMessage(message: string) {
            this.resetModal();
            this.createErrorMessage = message;
        },

        clearErrorMessage() {
            this.createErrorMessage = "";
        }
    }
});
